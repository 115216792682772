import type { FeaturedThreadTags } from "~/utils/thread";
import { Link, useRouteLoaderData } from "@remix-run/react";
import { isSSR } from "~/utils/ssr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { loadTranslator } from "~/utils/loadTranslator";

interface FeaturedTagsProps {
  tags: FeaturedThreadTags;
}

export default function FeaturedTags({ tags }: FeaturedTagsProps) {
  if (!isSSR() && window.innerWidth < 768) return null;
  const t = loadTranslator(useRouteLoaderData("root").translations)

  return (
    <div className="py-3">
      <div className="text-pri dark:text-pri-d border border-pri dark:border-pri-d bg-pri-d/[.065] dark:bg-pri/[.065] rounded-xl p-2">
        <div className="p-2 sticky top-0 z-0 font-bold">{t("trending-tags")}</div>
        <div className="mb-1">
          {tags?.slice(0, 7).map(({ tag, count }, index) => (
            <Tag key={tag} tag={tag} count={count} index={index} />
          ))}
        </div>
        <div>
          <Link
            prefetch="intent"
            to="/threads/trending-tags"
            className="flex flex-1 rounded-lg cursor-pointer text-sm font-medium text-acc dark:text-acc-d hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05]"
          >
            <button className="flex flex-1 justify-start text-start p-3">
              {t("show-more")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

interface TagProps {
  tag: string;
  count: number;
  index: number;
}

function Tag({ tag, count, index }: TagProps) {
  return (
    <Link
      prefetch="render"
      to={`/threads/tag/${tag}`}
      className="px-3 py-2 rounded-lg flex flex-col justify-center cursor-pointer hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05]"
    >
      <div className="flex flex-1 items-center gap-x-4">
        <FontAwesomeIcon icon={faHashtag} size="lg" />
        <div className="flex flex-1 items-center justify-between">
          <div className="flex flex-col flex-1 gap-y-1">
            <div className="font-semibold">{tag}</div>
            <div className="leading-none text-xxs font-medium text-gray-500 dark:text-zinc-500">
              {count} threads
            </div>
          </div>

          {index < 3 && <div className="text-lg">🔥</div>}
        </div>
      </div>
    </Link>
  );
}
